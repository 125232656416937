import { type FC } from "react"
import { CategorySelector } from "./CategorySelector"
import { StyledHeader, StyledLeft, StyledRight } from "./StyledHeader"
import { SectionTitle } from "@/styles/utils/Utils"

type HeaderPropsType = {
  onSelectCategory: (categoryId: string) => void
  onUnselectCategory: () => void
}

export const Header: FC<HeaderPropsType> = ({
  onSelectCategory,
  onUnselectCategory,
}) => (
  <StyledHeader>
    <StyledLeft>
      <SectionTitle>Новинки</SectionTitle>
    </StyledLeft>
    <StyledRight>
      <CategorySelector
        onSelect={onSelectCategory}
        onUnselect={onUnselectCategory}
      />
    </StyledRight>
  </StyledHeader>
)
