import { type FC, memo, useMemo } from "react"
import { Select } from "@/components/ui/Select/Select"
import { useAppSelector } from "@/hooks/redux"

type CategorySelectorPropsType = {
  onSelect: (categoryId: string) => void
  onUnselect: () => void
}

const ALL_CATEGORIES = "--all-categories--"

export const CategorySelector: FC<CategorySelectorPropsType> = memo(
  ({ onSelect, onUnselect }) => {
    const currentCategory = useAppSelector(
      (state) => state.app.news.currentCategory,
    )
    const { categories } = useAppSelector((state) => state.categories)
    const items = useMemo(
      () => [
        {
          value: ALL_CATEGORIES,
          name: "Все категории",
        },
        ...Object.values(categories?.fetched ?? [])
          .filter(({ news }) => news)
          .map(({ uuid = "", name = "" }) => ({
            value: uuid,
            name: name,
          })),
      ],
      [categories?.fetched],
    )

    return (
      <Select
        initialValue={!!currentCategory ? currentCategory : ALL_CATEGORIES}
        items={items}
        variant={"small"}
        onSelectValue={(categoryId) =>
          categoryId === ALL_CATEGORIES ? onUnselect() : onSelect(categoryId)
        }
      />
    )
  },
)

CategorySelector.displayName = "CategorySelector"
