import { FC } from "react"
import { ProductsGrid } from "../../Products/Grid"
import { StyledTileProducts } from "../../Products/StyledProductsGrid"
import { useAppDispatch, useAppSelector } from "@/hooks/redux"
import { Container } from "@/styles/utils/StyledGrid"
import { SectionProducts, SectionTitle } from "@/styles/utils/Utils"
import { CatalogResponseType } from "@/types"
import { VIEW_PRODUCTS_GRID } from "@/utils/constants"
import { fetchCatalog, fetchHits } from "@/api/catalogAPI"
import {
  resetHitsCurrentCategory,
  resetNewsCurrentCategory,
  setHits,
  setHitsCurrentCategory,
  setNews,
  setNewsCurrentCategory,
} from "@/store/reducers/appSlice"
import { useQuery } from "react-query"
import { Header } from "./Header"
import { StyledEmpty } from "./Styled"

export type NewPropsType = {
  initial?: CatalogResponseType
}

export const New: FC<NewPropsType> = () => {
  const dispatch = useAppDispatch()
  const currentCategoryUUID = useAppSelector(
    (state) => state.app.news.currentCategory,
  )
  const products = useAppSelector((state) =>
    !!currentCategoryUUID
      ? state.app.news.byCategory[currentCategoryUUID]
      : state.app.news.initial,
  )

  useQuery(
    ["products", "news", currentCategoryUUID],
    () =>
      fetchCatalog({
        isEnabled: "1",
        page: 1,
        perPage: 20,
        isNew: true,
        categories: [`${currentCategoryUUID}`],
      }),
    {
      onSuccess({ products }) {
        if (!currentCategoryUUID) {
          return
        }
        dispatch(
          setNews({
            categoryId: currentCategoryUUID,
            products: products as any,
          }),
        )
      },
      enabled: !!currentCategoryUUID,
    },
  )

  return (
    <SectionProducts>
      <Container>
        <Header
          onSelectCategory={(categoryId) =>
            dispatch(setNewsCurrentCategory(categoryId))
          }
          onUnselectCategory={() => dispatch(resetNewsCurrentCategory())}
        />
        {!!products && products.length > 0 ? (
          <ProductsGrid
            as={StyledTileProducts}
            products={products}
            view={VIEW_PRODUCTS_GRID}
          />
        ) : !!currentCategoryUUID ? (
          <StyledEmpty>В выбранной категории нет товаров-новинок</StyledEmpty>
        ) : null}
      </Container>
    </SectionProducts>
  )
}

New.displayName = "New"
